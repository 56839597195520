import React from 'react'
import { Helmet } from 'react-helmet'
import { useRouter } from 'next/router'
import Logo from 'components/Logo'
import Footer from 'components/App/Footer'
import Link from 'next/link'

const Login = () => {
  const router = useRouter()

  const loginWith = (type) => {
    switch (type) {
      case 'github':
        router.replace('/app')
        break
    }
  }

  return (
    <>
      <Helmet>
        <title>Log In</title>
      </Helmet>
      <div className="min-h-screen flex flex-col items-center bg-gray-50 px-4 sm:px-6 lg:px-8">
        <div className="flex-1 h-full max-w-sm w-full flex items-center justify-center">
          <div>
            <div>
              <Logo withoutText className="mx-auto h-14 w-auto" />
              <h2 className="mt-6 text-center text-3xl leading-9 font-extrabold text-gray-800">
                Log in to your account
              </h2>
              <p className="mt-2 text-center leading-5 text-gray-600">
                Don't have an account?{' '}
                <Link href="/signup">
                  <a className="hover:text-gray-500 font-semibold transition duration-150 ease-in-out">
                    Sign Up
                  </a>
                </Link>
              </p>
            </div>
            <div className="mt-14">
              <div className="w-full inline-flex rounded-md shadow-sm">
                <button
                  type="button"
                  className="w-full inline-flex items-center justify-center py-3 px-4 border border-gray-700 rounded-md bg-gray-900 hover:bg-gray-700 leading-5 font-medium text-white focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out"
                  onClick={() => loginWith('github')}
                >
                  <svg
                    className="h-5 flex-shrink-0 mr-2"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Continue with GitHub
                </button>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default Login
