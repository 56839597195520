import React from 'react'
import Link from 'next/link'

const Footer = () => {
  return (
    <div className="w-full max-w-6xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div className="mt-8 md:mt-0">
        <p className="text-center text-base leading-6 text-gray-400">
          &copy; 2020 Lighthouse Metrics
        </p>
      </div>
      <div className="flex space-x-4 justify-center md:justify-start mt-2 md:mt-0">
        <Link href="/privacy-policy">
          <a className="text-base leading-6 text-gray-400 hover:text-gray-800">
            Privacy Policy
          </a>
        </Link>

        <Link href="/terms-and-conditions">
          <a className="text-base leading-6 text-gray-400 hover:text-gray-800">
            Terms & Conditions
          </a>
        </Link>
      </div>
    </div>
  )
}

export default Footer
